* {
  box-sizing: border-box;
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  height: 100%;
}

body {
  min-height: 100%;
  margin: 0;
}

.w-container {
  max-width: 1200px;
}

.intl-tel-input {
  display: block !important;
}

.body-5 {
  height: 100%;
  margin: 0;
}

.main-section {
  background-image: linear-gradient(#1d426b, #627c98);
  height: auto;
  min-height: 100%;
}

.flex-container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
}

.hero {
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 90px;
  display: flex;
  position: relative;
}

.w-inline-block {
  max-width: 100%;
  text-decoration: none;
  display: inline-block;
}

.logo-link {
  margin-left: 30px;
  position: absolute;
  inset: 0% auto auto 0%;
}

.logo {
  width: 180px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.hero-tagline {
  color: #fff;
  font-family: Mulish, sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
}

.pagination-container {
  justify-content: center;
  align-items: center;
  width: 50%;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  position: relative;
  top: 5.5em;
}

.page {
  background-color: #ffffff61;
  border: 1px solid #c1c1c1;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  display: flex;
}

.page.selected {
  background-color: #e7f0fe;
}

.pagination-text {
  margin-bottom: 0;
  font-family: Mulish, sans-serif;
  font-size: 12px;
}

.divider {
  border: .5px solid #c1c1c1;
  width: 5%;
}

.pagination-text.white-text {
  color: #fff;
}

.login-link {
  margin-right: 30px;
  position: absolute;
  inset: 0% 0% auto auto;
}

.pagination-text.white-text.ns {
  color: #fff;
  text-align: center;
  margin-top: 8px;
  font-size: 24px;
  font-weight: 700;
}

.form-container {
  background-color: #fff;
  border-radius: 4px;
  width: 35%;
  padding: 20px;
}

.form-page {
  height: 100%;
  margin-left: 0;
  margin-right: auto;
}

.form-page.pg1._0pd {
  max-width: none;
  padding-bottom: 0;
}

.form-page.pg2._0pd {
  max-width: none;
  padding-bottom: 0;
  display: none;
}

.form-body {
  margin-left: 0;
  margin-right: 0;
  padding-top: 0;
}

.w-form {
  margin: 0 0 15px;
}

form {
  margin-top: 0;
  display: block;
}

.form-block-5 {
  margin-bottom: 0;
}

.form-6 {
  position: relative;
}

.form-fields {
  color: #383e46;
  margin-bottom: 8px;
  font-family: Mulish, sans-serif;
  font-size: 16px;
  line-height: 24px;
}

.required {
  color: red;
}

.input-field {
  color: #3b495f;
  border: 1px solid #c1c1c1;
  border-radius: 2px;
  height: 48px;
  margin-bottom: 16px;
  padding: 14px 15px;
  font-family: Mulish, sans-serif;
  font-size: 18px;
  font-weight: 400;
}

.input-field.err {
  border-color: red;
}

.error-field {
  color: red;
  margin-top: -12px;
  margin-bottom: 10px;
  font-family: Mulish, sans-serif;
  font-size: 15px;
  display: none;
}

.tick {
  width: 25px;
  display: none;
  position: absolute;
  top: 12%;
  left: 90%;
}

.w-input {
  vertical-align: middle;
  background-color: #fff;
  width: 100%;
  line-height: 1.42857;
  display: block;
}

.loader {
  border: 2px solid #f3f3f3;
  border-top-color: #3670fe;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: 1s linear infinite spin;
  display: none;
  position: absolute;
  top: 40%;
  left: 46%;
}

.loader.t {
  top: 9%;
  left: 90%;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.password-container {
  position: relative;
}

div {
  display: block;
}

.password-hint {
  text-align: center;
  color: #fff;
  background: #000;
  border-radius: 5px;
  width: 200px;
  padding: 7px 10px;
  font-family: Mulish, sans-serif;
  font-size: 16px;
  line-height: 18.96px;
  display: none;
  position: absolute;
  top: -20%;
  left: 103%;
}

.password-hint:after {
  content: " ";
  pointer-events: none;
  border: 8px solid #88b7d500;
  border-right-color: #000;
  width: 0;
  height: 0;
  margin-top: -8px;
  position: absolute;
  top: 50%;
  right: 100%;
}

@media screen and (width <= 991px) {
  .password-hint, .password-hint:after {
    display: none !important;
  }

  .form-container {
    width: 65%;
  }
}

@media screen and (width <= 479px) {
  .form-container {
    width: 95%;
  }

  .pagination-text.white-text.ns {
    font-size: 21px;
  }

  .logo {
    width: 150px;
  }
}

.data-center-text {
  color: #383e46;
  margin-bottom: 15px;
  font-family: Mulish, sans-serif;
  font-size: 16px;
  line-height: 18.96px;
}

.radio-container {
  margin-bottom: 20px;
}

.w-embed:before, .w-embed:after {
  content: " ";
  grid-area: 1 / 1 / 2 / 2;
  display: table;
}

.w-embed:after {
  clear: both;
}

input {
  text-rendering: auto;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0;
  text-shadow: none;
  text-align: start;
  -webkit-rtl-ordering: logical;
  display: inline-block;
}

input[type="radio" i] {
  background-color: initial;
  cursor: default;
  appearance: auto;
  border: initial;
}

input:not([type="range"]):not([type="color"]):not([type="button"]):not([type="reset"]):not([type="submit"]):not([type="file"]) {
  writing-mode: horizontal-tb !important;
}

button, input, optgroup, select, textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}

input {
  line-height: normal;
}

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

.radio-button-label-3 {
  color: #383e46;
  font-family: Rubik, sans-serif;
  font-size: 16px;
  font-weight: 400;
  display: inline-block;
}

.eye {
  cursor: pointer;
  width: 20px;
  position: absolute;
  top: 30%;
  left: 90%;
}

.eye.op {
  display: none;
  top: 34.5%;
}

img {
  vertical-align: middle;
  border: 0;
  max-width: 100%;
  display: inline-block;
}

.button {
  color: #fff;
  text-align: center;
  background-color: #1d426b;
  border-radius: 4px;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 20px;
  font-family: Mulish, sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}

.button:disabled {
  opacity: .5;
  cursor: not-allowed;
}

button, [type="button"], [type="reset"] {
  cursor: pointer;
  -webkit-appearance: button;
  border: 0;
}

.agreement {
  color: #9da1a7;
  text-align: center;
  margin-bottom: 10px;
  font-family: Mulish, sans-serif;
  font-size: 12px;
}

a {
  color: #3670fe;
  background-color: #0000;
  font-weight: 400;
  text-decoration: none;
}

.have-account {
  color: #626e7b;
  text-align: center;
  font-family: Mulish, sans-serif;
}

.form-body.setup {
  padding-top: 0;
}

.tooltip-container {
  background-color: #fff;
  border-radius: 3px;
  min-width: 250px;
  padding: 10px;
  line-height: 25px;
  position: absolute;
  top: -80px;
  box-shadow: 1px 1px 19px #00000029;
}

.tooltip-arrow-new {
  z-index: auto;
  background-color: #fff;
  width: 10px;
  height: 10px;
  position: relative;
  top: 14px;
  left: 30px;
  transform: rotate(45deg);
  box-shadow: 1px 1px 3px #00000029;
}

.phone-tooltip {
  font-family: Mulish, sans-serif;
  font-weight: 400;
}

.intl-tel-input {
  display: inline-block;
  position: relative;
}

.wrapper {
  min-height: 100vh;
  display: flex;
}

.item-left {
  width: 50%;
}

.item-right {
  background-color: #fff;
  width: 50%;
}

.create-account {
  color: #09325e;
  font-family: Mulish, sans-serif;
  font-size: 21px;
  font-weight: 500;
}

.credit-card {
  color: #363e47;
  margin-top: 10px;
  font-family: Mulish, sans-serif;
  font-size: 10px;
  font-style: italic;
  font-weight: 400;
}

@media screen and (width <= 991px) {
  .item-left {
    display: none;
  }

  .item-right {
    background-color: #0000;
    width: 100%;
    height: 100vh;
  }

  .create-account, .credit-card, #login {
    color: #fff !important;
  }
}

@media screen and (width <= 479px) {
  .form-container {
    width: 95% !important;
  }
}

/*# sourceMappingURL=index.81580867.css.map */

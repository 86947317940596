* {
  box-sizing: border-box;
}
html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  height: 100%;
}
body {
  min-height: 100%;
  margin: 0;
}
.w-container {
  max-width: 1200px;
}
.intl-tel-input {
  display: block !important;
}
.body-5 {
  height: 100%;
  margin: 0;
}
.main-section {
  height: auto;
  min-height: 100%;
  background-image: linear-gradient(#1d426b, #627c98);
}
.flex-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 0px;
  padding-bottom: 0px;
}
.hero {
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 90px;
  display: flex;
  position: relative;
}
.w-inline-block {
  max-width: 100%;
  display: inline-block;
  text-decoration: none;
}
.logo-link {
  margin-left: 30px;
  position: absolute;
  top: 0%;
  bottom: auto;
  left: 0%;
  right: auto;
}
.logo {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 180px;
}
.hero-tagline {
  color: #fff;
  font-family: Mulish, sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
}
.pagination-container {
  align-items: center;
  margin-top: 10px;
  display: flex;
  width: 50%;
  justify-content: center;
  position: relative;
  top: 5.5em;
	margin-bottom: 10px;
}
.page {
  width: 30px;
  height: 30px;
  border: 1px solid #c1c1c1;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: #ffffff61;
}
.page.selected {
  background-color: #e7f0fe;
}
.pagination-text {
  margin-bottom: 0;
	font-family: Mulish, sans-serif;
  font-size: 12px;
}
.divider {
  width: 5%;
  border: 0.5px solid #c1c1c1;
}
.pagination-text.white-text {
  color: #fff;
}
.login-link {
  margin-right: 30px;
  position: absolute;
  top: 0%;
  bottom: auto;
  left: auto;
  right: 0%;
}
.pagination-text.white-text.ns {
  color: #fff;
  font-weight: 700;
  margin-top: 8px;
  text-align: center;
  font-size: 24px;
}
.form-container {
  width: 35%;
  background-color: #fff;
  border-radius: 4px;
  padding: 0 20px 20px;
  padding-top: 20px;
}
.form-page {
  height: 100%;
  margin-left: 0;
  margin-right: auto;
}
.form-page.pg1._0pd {
  max-width: none;
  padding-bottom: 0;
}
.form-page.pg2._0pd {
  max-width: none;
  padding-bottom: 0;
  display: none;
}
.form-body {
  margin-left: 0;
  margin-right: 0;
  padding-top: 0;
}
.w-form {
  margin: 0 0 15px;
}
form {
  display: block;
  margin-top: 0em;
}
.form-block-5 {
  margin-bottom: 0;
}
.form-6 {
  position: relative;
}
.form-fields {
  color: #383e46;
  margin-bottom: 8px;
  font-family: Mulish, sans-serif;
  font-size: 16px;
  line-height: 24px;
}
.required {
  color: red;
}
.input-field {
  height: 48px;
  color: #3b495f;
  border: 1px solid #c1c1c1;
  border-radius: 2px;
  margin-bottom: 16px;
  padding: 14px 15px;
  font-family: Mulish, sans-serif;
  font-size: 18px;
  font-weight: 400;
}
.input-field.err {
  border-color: red;
}
.error-field {
  color: red;
  margin-top: -12px;
  margin-bottom: 10px;
  font-family: Mulish, sans-serif;
  font-size: 15px;
  display: none;
}
.tick {
  width: 25px;
  display: none;
  position: absolute;
  top: 12%;
  left: 90%;
}
.w-input {
  width: 100%;
  vertical-align: middle;
  background-color: #fff;
  line-height: 1.42857;
  display: block;
}
.loader {
  border: 2px solid #f3f3f3;
  border-top: 2px solid #3670fe;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
  position: absolute;
  top: 40%;
  left: 46%;
  display: none;
}
.loader.t {
  top: 9%;
  left: 90%;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.password-container {
  position: relative;
}
div {
  display: block;
}
.password-hint {
  font-family: Mulish, sans-serif;
  font-size: 16px;
  line-height: 18.96px;
  display: none;
  border-radius: 5px;
  text-align: center;
  position: absolute;
  background: black;
  left: 103%;
  top: -20%;
  width: 200px;
  padding: 7px 10px;
  color: #fff;
}
.password-hint:after {
  right: 100%;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(136, 183, 213, 0);
  border-right-color: black;
  border-width: 8px;
  margin-top: -8px;
}
@media screen and (max-width: 991px) {
  .password-hint {
    display: none !important;
  }
  .password-hint:after {
    display: none !important;
  }
  .form-container {
    width: 65%;
  }
}
@media screen and (max-width: 479px) {
  .form-container {
    width: 95%;
  }
  .pagination-text.white-text.ns {
    font-size: 21px;
  }
  .logo {
    width: 150px;
  }
}
.data-center-text {
  color: #383e46;
  margin-bottom: 15px;
  font-family: Mulish, sans-serif;
  font-size: 16px;
  line-height: 18.96px;
}
.radio-container {
  margin-bottom: 20px;
}
.w-embed:before,
.w-embed:after {
  content: " ";
  grid-area: 1/1/2/2;
  display: table;
}
.w-embed:after {
  clear: both;
}
input {
  text-rendering: auto;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  text-align: start;
  -webkit-rtl-ordering: logical;
}
input[type="radio" i] {
  background-color: initial;
  cursor: default;
  appearance: auto;
  border: initial;
}
input:not([type="range"]):not([type="color"]):not([type="button"]):not(
    [type="reset"]
  ):not([type="submit"]):not([type="file"]) {
  writing-mode: horizontal-tb !important;
}
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}
input {
  line-height: normal;
}
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
.radio-button-label-3 {
  color: #383e46;
  font-family: Rubik, sans-serif;
  font-size: 16px;
  font-weight: 400;
  display: inline-block;
}
.eye {
  width: 20px;
  cursor: pointer;
  position: absolute;
  top: 30%;
  left: 90%;
}
.eye.op {
  display: none;
  top: 34.5%;
}
img {
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
  border: 0;
}
.button {
  margin-top: 20px;
  background-color: #1d426b;
  font-family: Mulish, sans-serif;
  width: 100%;
  color: #fff;
  text-align: center;
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 20px;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}
.button:disabled {
  opacity: 50%;
  cursor: not-allowed;
}
button,
[type="button"],
[type="reset"] {
  cursor: pointer;
  -webkit-appearance: button;
  border: 0;
}
.agreement {
  color: #9da1a7;
  text-align: center;
  margin-bottom: 10px;
  font-family: Mulish, sans-serif;
  font-size: 12px;
}
a {
  color: #3670fe;
  font-weight: 400;
  text-decoration: none;
  background-color: transparent;
}
.have-account {
  color: #626e7b;
  text-align: center;
  font-family: Mulish, sans-serif;
}
.form-body.setup {
  padding-top: 0;
}
.tooltip-container {
  min-width: 250px;
  background-color: #fff;
  border-radius: 3px;
  padding: 10px;
  line-height: 25px;
  position: absolute;
  top: -80px;
  box-shadow: 1px 1px 19px rgba(0, 0, 0, 0.16);
}
.tooltip-arrow-new {
  z-index: auto;
  width: 10px;
  height: 10px;
  background-color: #fff;
  position: relative;
  top: 14px;
  left: 30px;
  transform: rotate(45deg);
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.16);
}
.phone-tooltip {
  font-family: Mulish, sans-serif;
  font-weight: 400;
}
.intl-tel-input {
  position: relative;
  display: inline-block;
}
.wrapper {
  display: flex;
  min-height: 100vh;
}
.item-left {
  width: 50%;
}
.item-right {
  width: 50%;
  background-color: white;
}
.create-account{
  font-family: Mulish, sans-serif;
  color: #09325e;
  font-size: 21px;
  font-weight: 500;
}
.credit-card {
  font-family: Mulish, sans-serif;
  color: #363e47;
  font-size: 10px;
  font-weight: 400;
  margin-top: 10px;
  font-style: italic;
}
@media screen and (max-width: 991px) {
  .item-left {
    display: none;
  }
  .item-right {
    width: 100%;
    height: 100vh;
    background-color: transparent;
  }
  .create-account, .credit-card, #login{
    color: white !important;
  }
}
@media screen and (max-width: 479px) {
  .form-container {
    width: 95% !important;
  }
}
